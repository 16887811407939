#root, html {
  width: 100%;
  padding: 0;
  margin: 0;
  position:relative;
  /* left: 20; */
  background-color: #252525;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                               NAV BAR
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/
.nav{
  background-color: #1b1b1b;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 1;
}
.nav-bar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  padding-left: 40px;
  padding-right: 40px;
}

#nav-bar-links{
  display: inline;
  text-decoration: none;
}

#nav-link {
  text-decoration: none;
  color: white;
}

#nav-link:hover{
  cursor: pointer;
}

/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                               HOME
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/
.intro-box{
  display: flex;
  box-sizing: content-box;
  background-color: rgba(197, 212, 222, 0.7);
  height: 50vh;
  width: 70vw;
  margin: auto;
  margin-top: 25vh;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 2em;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.i-am{
  display: flex;
  flex-direction: row;
}

.title-name {
  margin: 0;
  margin-top: 1em;
  padding: 0;
  color: white;
}

span {
  display: block;
  height: 100%;
  padding-left: 10px;
  animation: spin_words 6s infinite;
}

.words {
  overflow: hidden;
}

@keyframes spin_words {
  10% {
    transform: translateY(18%);
  }
  30% {
    transform: translateY(-84%);
  }
  50% {
    transform: translateY(-183%);
  }
  70% {
    transform: translateY(-282%);
  }
  90% {
    transform: translateY(-381%);
  }
}

/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                               ABOUT ME 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/
.about-me-box{
  display: flex;
  box-sizing: content-box;
  background-color: rgba(197, 212, 222, 0.7);
  height: 50vh;
  width: 70vw;
  margin: auto;
  margin-top: 45vh;
  margin-bottom: 25vh;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 2em;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.about-me-name {
  margin: 0;
  padding: 0;
  color: white;
}

.itisi, .red{
  padding: 0 4em;
  font-size: large;
  font-weight: 500;
  text-align: center;
}

.red {
  color: red;
}
/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                              PROJECTS
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/
.projects {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row1{
  display: inherit;
  flex-direction: row;
  align-items: center;
}

.programming, .audios {
  display: grid;
  justify-items: center;
  background-color: rgba(197, 212, 222, 0.7);
  height: 70vh;
  width: 35vw;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 15vh;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 2em;
  font-weight: 500;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
}

.programming::-webkit-scrollbar,
.audios::-webkit-scrollbar{
  display: none;
}

#project-button {
  height: 10vh;
  margin-bottom: 15vh;
  width: 30vh;
  background-color: rgba(197, 212, 222, 0.7);
  padding: 0;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  display: grid;
}

#project-button:hover{
  cursor: pointer;
}

#button-text{
  font-weight: 500;
  font-size: medium;
  margin: 0;
  align-self: center;
  justify-self: center;
}

#google-drive {
  text-decoration: none;
}

#audio-title, #programming-title{
  align-self: center;
  font-weight: 700;
  font-size: larger;
  color: white;
}

#audio-projects, #programming-projects {
  display: inherit;
  justify-items: center;
}

#audio-project-title{
  margin: 1vh 0;
}

h5 {
  margin: 1vh 0;
  font-weight: 300;
  text-align: center;
}

audio {
  width: 33vw;
  height: 5vh;
  padding: 1vh 0;
}

iframe {
  width: 33vw;
  height: 30vh;
  justify-self: center;
  margin: 1vh 0;
}

#audio-description {
  font-weight: 300;
  font-size: medium;
  text-align: justify;
  padding: 0 10px;
}

#button-1,
#button-2,
#button-3,
#button-4{
  padding: 2vh;
}

#button-title{
  font-weight: 800;
  color: white;
}
#button-title-1{
  font-weight: 800;
  color: #252525;
}

#programming-project-button-1, 
#programming-project-button-2,
#programming-project-button-3,
#programming-project-button-4{
  width: 33vw;
  height: 25vh;
  cursor: pointer;
  border-radius: 25px;
  border: 0;
  background-size: cover;
}


#programming-project-button-1{
  background-image: url(../public/images/initial.png);
}

#programming-project-button-2{
  background-image: url(../public/images/crist.png);
}

#programming-project-button-3{
  background-image: url(../public/images/wardrobe.png);
}

#programming-project-button-4{
  background-image: url(../public/images/wshlst.png);
}

#programming-description-1 {
  display: none;
  font-weight: 300;
  font-size: medium;
  text-align: justify;
  padding: 0 10px;
}

#button-1:hover + #programming-description-1{
  display: inherit;
}

#programming-description-2 {
  display: none;
  font-weight: 300;
  font-size: medium;
  text-align: justify;
  padding: 0 10px;
}

#button-2:hover + #programming-description-2{
  display: inherit;
}

#programming-description-3 {
  display: none;
  font-weight: 300;
  font-size: medium;
  text-align: justify;
  padding: 0 10px;
}

#button-3:hover + #programming-description-3{
  display: inherit;
}

#programming-description-4 {
  display: none;
  font-weight: 300;
  font-size: medium;
  text-align: justify;
  padding: 0 10px;
}

#button-4:hover + #programming-description-4{
  display: inherit;
}

/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                             RESUME
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

.resume-box{
  display: flex;
  box-sizing: content-box;
  background-color: rgba(197, 212, 222, 0.7);
  height: 50vh;
  width: 70vw;
  margin: auto;
  margin-top: 45vh;
  margin-bottom: 25vh;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 2em;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.resume-name {
  margin: 0;
  padding: 0;
  color: white;
}

.resume-note{
  font-size: small;
}

.resumelink {
  height: 20vh;
  width: 30vw;
  margin: 1vh;
}
.resumebutton{
  height: 20vh;
  width: 30vw;
  border-radius: 25px;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgb(225,237,245);
}

.resumebutton:hover{
cursor: pointer;
}
/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                             CONTACT
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

.contact-box{
  display: flex;
  box-sizing: content-box;
  background-color: rgba(197, 212, 222, 0.7);
  height: 65vh;
  width: 70vw;
  margin: auto;
  margin-top: 45vh;
  margin-bottom: 25vh;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 2em;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.contact-name {
  margin: 0;
  padding: 0;
  color: white;
}

.contact-note {
  margin: 0;
  padding: 1vh;
  text-align: center;
  font-size: large;
}

.contact-form {
  height: 60vh;
  width: 40vw;
  background-color: rgb(216227235);
  display: flex;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  margin-bottom: 6vh;
  margin-top: 4vh;
}

form {
  height: 40vh;
  width: 32vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

input {
  padding-top: 3px;
  margin-bottom: 3.5vh;
  border-radius: 1em;
}

#message-box{
  height: 15vh;
}

/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                             To Top
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

.scroll-container {
  position: absolute;
  top: 0;
  height: 100%;
  right:20px;
}

.scroll-container:before {
  content: '';
  display: block;
  height: 100vh;
  pointer-events: none;
}

.scroll-container a {
  position: sticky;
  top: 88vh;
  font-size: 20px;
}

#tothetop {
  color: black;
  z-index: 3;
  height: 50px;
  width: 50px;
}

#tothetopbutton:hover{
  cursor: pointer;
}

#tothetopbutton {
  height: 50px;
  width: 50px;
  padding: 0;
  border: 0;
  border-radius: .5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(197, 212, 222, 0.7);
}